import React,{useState,useEffect} from "react";

import { useStore } from "../store/useStore";
import axios from "axios";
import { config } from "../config";
import InputDouble from "./form/InputDouble.js";

import  {ReactComponent as ICON_ASETUKSET} from '../icons/asetukset.svg';
import  {ReactComponent as ICON_MAKSAMINEN} from '../icons/maksaminen.svg';
import  {ReactComponent as ICON_PALKANLASKENTA} from '../icons/palkanlaskenta.svg';

import  {ReactComponent as ICON_TYONTEKIJAT} from '../icons/tyontekijat.svg';
import  {ReactComponent as ICON_REPORT} from '../icons/report.svg';
import  {ReactComponent as ICON_BONUS} from '../icons/bonus.svg';

import  {ReactComponent as ICON_LOAN} from '../icons/loan.svg';
import  {ReactComponent as ICON_CREDIT} from '../icons/credit.svg';

import {
	BrowserRouter as Router,
	StaticRouter, // for server rendering
	Route,
	Link,
    Nav,NavLink
	// etc.
  } from "react-router-dom";

export default function Persons(props) {
	const { state, dispatch } = useStore();
 
  

	
	
	if(!state.users.items){
		return null;
	}



	/**/ 

	const logout=()=>{
		
		dispatch({ type: "LOGOUT" });
		dispatch({ type: "COMMON_LOGOUT" });
		dispatch({ type: "USER_LOGOUT" });
		
		
	};
	const ADM=(state.common.me.role.is_super_admin===1?true:false);
	const IS_SIHTEERI=(state.common && state.common.me && state.common.me.role.is_sihteeri===1?true:false);

	console.log("IS ADM?",ADM);
	return (
		<div className={config.isDev?"sidenav dev":"sidenav"}>
			
            
                {!props.is_frontpage===true&&<h1>D</h1>}
               <ul>
				
			   {ADM && <li><NavLink activeClassName="active" to="/persons"><ICON_TYONTEKIJAT />Työntekijät</NavLink></li>}
                  
				  
				  {!IS_SIHTEERI&&<li><NavLink activeClassName="active" to="/palkanlaskenta"><ICON_PALKANLASKENTA />Palkat</NavLink></li>}
                   
				{ADM &&<li><NavLink activeClassName="active" to="/netto"><ICON_CREDIT />-Netto</NavLink></li>}
				{ADM &&<li><NavLink activeClassName="active" to="/asetukset"><ICON_ASETUKSET />Asetukset</NavLink></li>}
				{ADM &&<li><NavLink activeClassName="active" to="/yrityslainat"><ICON_LOAN />Lainat</NavLink></li>}
				
				{ADM &&<li><NavLink activeClassName="active" to="/pankki"><ICON_LOAN />Pankki</NavLink></li>}


				{(ADM || IS_SIHTEERI)&&<li><NavLink activeClassName="active" to="/tapahtumat"><ICON_BONUS />Tapahtumat</NavLink></li>}


				{ADM &&<li><NavLink activeClassName="active" to="/bonuses"><ICON_BONUS />Bonukset</NavLink></li>}
					
					 {/*<li><NavLink activeClassName="active" to="/lisapalkat"><ICON_PALKANLASKENTA />Lisäpalkat</NavLink></li>*/}
					 {ADM &&<li><NavLink activeClassName="active" to="/kayttajahallinta"><ICON_TYONTEKIJAT />Käyttäjähallinta</NavLink></li>}

{ADM &&<li><NavLink activeClassName="active" to="/memo"><ICON_PALKANLASKENTA />Memo</NavLink></li>}

{!IS_SIHTEERI&&<li><NavLink activeClassName="active" to="/raportit"><ICON_REPORT />Raportit</NavLink></li>}
{!IS_SIHTEERI&& <li><NavLink activeClassName="active" to="/raportithenkilot"><ICON_REPORT />Henkilö Raportit</NavLink></li>}

					{!ADM&&<li><NavLink activeClassName="active" to="/omattiedot"><ICON_TYONTEKIJAT />Omat tiedot</NavLink></li>}



					 <li id="logoutli"><span id="logout" onClick={(e=>logout(e))}>Log out.</span></li>

               </ul>
				
				
					
					
			
			
		</div>
	);
}
