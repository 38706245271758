import React,{useState,useEffect} from "react";
import { useStore } from "../store/useStore";
import { searchSetting } from "../commonFunctions";
import axios from "axios";
import { config } from "../config";
import InputText from "./form/InputText.js";
import InputSelect from "./form/InputSelect.js";
import InputDouble from "./form/InputDouble.js";

import AdminUser from "./form/AdminUser.js";



import SettingPair from "./form/SettingPair.js";

import { getSettingValue,getSettingType,getSettingIndex,getCommonSetting } from "../commonFunctions";

import TaxRates from "./form/TaxRates.js";


import TopNav from "./TopNav.js";

import {
	BrowserRouter as Router,
	StaticRouter, // for server rendering
	Route,
	Link
	// etc.
  } from "react-router-dom";
import KayttajaEditor from "./KayttajaEditor.js";




export default function OmatTiedot(props) {

    const { state, dispatch } = useStore();
    const ME=(state.common.me);
   
    if(!state.common || !state.common.me || !ME.id){
        return null;

    }



    return <div><KayttajaEditor id={ME.id}/></div>;
    
}
