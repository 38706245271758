


export default function InputDouble(props) {


  const onFocus=(e)=>{

    if(props.onFocus){
      let name = e.target.name;
      let value = e.target.value;
      props.onFocus(name,(value),props.index);
    
    }
};


    const changeInputValue = (e) => {



		let name = e.target.name;
		let value = e.target.value;

console.log("TESTAAN",value,value.length);
      //console.log(value,parseFloat(value));
      value=value.replace(",",".");
var dot=".";

console.log("HITS",value.split(".").length);

      while(value.split(".").length>2){

        value=value.split("").reverse().join("");

       var dot_index= value.indexOf(".");

       value=value.substring(0,dot_index) + "" + value.substring(dot_index+1);

       value=value.split("").reverse().join("");

      }

      let allowed="1234567890.";

      if(value.length>1 && value[0]=="0" && value[1]!="."){
        value= value.substring(1);
      }
      


      for(var i=0;i<value.length;i++){

        console.log(value[i],allowed.indexOf(value[i]));
          if(allowed.indexOf(value[i])==-1){
          

           value=value.substring(0,i) + "" + value.substring(i+1);
          }
      }


        props.onChange(name,(value),props.index,props.targetkey);





     
	};
const onBlur=(e)=>{

if(props.onBlur){
  let name = e.target.name;
  let value = e.target.value;
  props.onBlur(name,(value),props.index,props.targetkey);

}
};
  const v=(props.data[props.name]?props.data[props.name]:0);
   
    const input=<input onFocus={(e) => {
      onFocus(e);
  }}  name={props.name} onBlur={(e) => {
      onBlur(e);
  }} value={v} 
    onChange={(e) => {
        changeInputValue(e);
    }} />;
    /*const d={props.edit==true?:{props.data[props.name]}};
   
    */

    const hasError=(props.errors && props.errors["data."+props.name]?true:false);

   const content=(props.edit==true?input:props.data[props.name]);
   const label=(props.label && props.label!==""? <label>{props.label}</label>:null);

	return (<div className={"input input-edit-"+(props.edit?"true":false)}>{label}{content}{(hasError?<p className="error-text">{props.errors["data."+props.name]}</p>:null)}</div>);
}
