import React, { useState, useEffect } from "react";
import { useStore } from "../store/useStore";
import { searchSetting } from "../commonFunctions";
import axios from "axios";
import { config } from "../config";
import InputText from "./form/InputText.js";
import InputSelect from "./form/InputSelect.js";
import InputDouble from "./form/InputDouble.js";

import AdminUser from "./form/AdminUser.js";
import {format} from "../commonFunctions";
import Period from "./form/Period.js";

import SettingPair from "./form/SettingPair.js";
import { apiClient } from "../apiCall";

import {
  getSettingValue,
  getSettingType,
  getSettingIndex,
  getCommonSetting,
} from "../commonFunctions";

import TaxRates from "./form/TaxRates.js";

import TopNav from "./TopNav.js";

import {
  BrowserRouter as Router,
  StaticRouter, // for server rendering
  Route,
  Link,
  // etc.
} from "react-router-dom";
import KayttajaEditor from "./KayttajaEditor.js";

export const defaultTapahtumaData = {
  period: null,
  person_id: null,
  data: null,
};
export const defaultFormValues = {
  juoma_kulut:null,
  tilausnumero: null,
  kasittely_kulut: null,
  perinta_kulut: null,
  muu_kulut: null,
  muu_selite: null,
    edit:false,
    id:null
};
export const DefaultRequestErrors = {
  errors: null,
};

export default function Tapahtumat(props) {
  const { state, dispatch } = useStore();
  const apiCall = apiClient(state, dispatch);
  const [TapahtumaData, setTapahtumaData] = useState({
    ...defaultTapahtumaData,
    period: state.common.current_period,
  });
  const [FormValues, setFormValues] = useState({
    ...defaultFormValues,
  });
  const [RequestErrors, setRequestErrors] = useState({
    ...DefaultRequestErrors,
  });
  const cancel=()=>{
    setFormValues( {...defaultFormValues});

  };
  const apiResponse = (data) => {
    if (data) {
      setTapahtumaData({ ...TapahtumaData, data: { ...data } });
    }
  };
  const onFocus = (name, value, index) => {
    let key = name;

    let errors = { ...RequestErrors.errors };
    errors[key] = null;
    setRequestErrors({ errors: { ...errors } });
    console.log(key);
    if (
      key == "kasittely_kulut" ||
      key == "perinta_kulut" ||
      key == "muu_kulut" ||
      key == "juoma_kulut"
    ) {
      onFocus("at_least_one_price");
    }
  };
  useEffect(() => {
    if (
      TapahtumaData.period !== null &&
      TapahtumaData.person_id != 0 &&
      TapahtumaData.person_id !== null
    ) {
      let url =
        "/tapahtumat/" + TapahtumaData.person_id + "/" + TapahtumaData.period;

      fetch(config.apiUrl + url, {
        headers: new Headers({
          Authorization: "Bearer " + state.user.token,
        }),
      })
        .then((res) => res.json())
        .then((data) => apiResponse(data));
    }
  }, [TapahtumaData.period, TapahtumaData.person_id]);
  const changePeriod = (name, value, index) => {
    //props.onChange(name,value,index);
    setTapahtumaData({ ...TapahtumaData, period: value });

    setFormValues({  ...defaultFormValues});
   



  };

  const changePerson = (n, v) => {

    setFormValues({  ...defaultFormValues});
    
    if (v == 0) {
      setTapahtumaData({ ...TapahtumaData, person_id: null, data: null });
    } else {
      setTapahtumaData({ ...TapahtumaData, [n]: v });
    }
  };
  const changeInputValue = (name, value) => {
    console.log(name, value);

    setFormValues({ ...FormValues, [name]: value });
  };
  const getSelectPersonFilter = () => {
    const persons = state.users.items
      .map((person) => ({
        id: person.id,
        name: person.lastname + " " + person.firstname,
      }))
      .sort((a, b) => a.name.localeCompare(b.name));

    return (
      <InputSelect
        onChange={changePerson}
        zero="--Valitse henkilö--"
        options={persons}
        data={TapahtumaData}
        name="person_id"
        edit={true}
      />
    );
  };
  if (!state.users || !state.users.items) {
    return null;
  }
const edit=(item)=>{
  /*  tapahtuma: null,
    tilausnumero: null,
    kasittely_kulut: null,
    perinta_kulut: null,
    muu_kulut: null,
    muu_selite: null,
      edit:false,
      id:null*/


setFormValues({...item,edit:true});

};
const remove=(item)=>{

  if(window.confirm("Haluatko varmasti poistaa tapahtuman?")){
  var data = {  };
    
 
  let url = "tapahtumatremove/" + item.id;
  
   
  apiCall.ADD(url, { ...data }, function (status, err_msgs, response) {
    if (status == 400) {
      setRequestErrors({ errors: { ...err_msgs } });
    } else if (status == 200) {

     if(response && response.data){

      setFormValues({...defaultFormValues});
      setTapahtumaData({...TapahtumaData ,data:{...response.data}})
     }

    } else {
    }
  });

  }

};
  const period_object = (
    <Period
      onChange={changePeriod}
      name="period"
      value={TapahtumaData.period}
      edit={true}
    />
  );

  const makeGray=(v)=>{
      if(v==null){
        return "gray";
      }
      if(v==0){
        return "gray";
      }
      return "";
  }


  const tryToSubmit = () => {
    var data = { ...FormValues };
    
    let url = "tapahtumat/" + TapahtumaData.person_id + "/" + TapahtumaData.period;
    if(FormValues.edit){
         url = "tapahtumat/" + FormValues.id;
    }
     
    apiCall.ADD(url, { ...data }, function (status, err_msgs, response) {
      if (status == 400) {
        setRequestErrors({ errors: { ...err_msgs } });
      } else if (status == 200) {

       if(response && response.data){

        setFormValues({...defaultFormValues});
        setTapahtumaData({...TapahtumaData ,data:{...response.data}})
       }

      } else {
      }
    });
  };

  const onBlur = () => {};
  const getAddForm = () => {
    if (!TapahtumaData.data) {
      return null;
    }

    if (TapahtumaData.data.can_add) {
      console.log(FormValues);
      /*{/*
                    <td></td>
                    <td></td>
                   
                    <td className="smaller-input"><InputDouble onBlur={onBlur} onChange={changeInputValue}  name="kasittely_kulut" data={FormValues} edit={true}/></td>
                    <td className="smaller-input"><InputDouble onBlur={onBlur} onChange={changeInputValue}  name="perinta_kulut" data={FormValues} edit={true}/></td>
                    <td className="smaller-input"><InputDouble onBlur={onBlur} onChange={changeInputValue}  name="muu_kulut" data={FormValues} edit={true}/></td>
                    <td ><InputText onBlur={onBlur} onChange={changeInputValue}  name="muu_selite" data={FormValues} edit={true}/></td>
                    <td><InputText onBlur={onBlur} onChange={changeInputValue}  name="muu_selite" data={FormValues} edit={true}/></td>
                    <td><button onClick={(e)=>{}}>Tallenna</button></div>
            </tr>}*/
      return (
        <div className="form-blocks form-blocks-tapahtumat no-print">
            <h3>{FormValues.edit?"Muokkaa tapahtumaa":"Lisää uusi tapahtuma"}</h3>
          <div className="form-block">
           
           
            <div className="left">
              <InputText
                onFocus={onFocus}
                onBlur={onBlur}
                onChange={changeInputValue}
                label={"Tilausnumero"}
                name="tilausnumero"
                data={FormValues}
                edit={true}
                errors={RequestErrors.errors}
                useSimpleKey={true}
              />
            </div>
            <div className="right"></div>
            <div className="left">
              <InputDouble
                onFocus={onFocus}
                useSimpleKey={true}
                errors={RequestErrors.errors}
                onBlur={onBlur}
                onChange={changeInputValue}
                label={"Juomakulut"}
                name="juoma_kulut"
                data={FormValues}
                edit={true}
              />
            </div>
            <div className="left">
              <InputDouble
                onFocus={onFocus}
                useSimpleKey={true}
                errors={RequestErrors.errors}
                onBlur={onBlur}
                onChange={changeInputValue}
                label={"Käsittelykulut"}
                name="kasittely_kulut"
                data={FormValues}
                edit={true}
              />
            </div>
            <div className="right">
              <InputDouble
                onFocus={onFocus}
                useSimpleKey={true}
                errors={RequestErrors.errors}
                onBlur={onBlur}
                onChange={changeInputValue}
                label={"Perintäkulut"}
                name="perinta_kulut"
                data={FormValues}
                edit={true}
              />
            </div>

            <div className="left">
              <InputDouble
                onFocus={onFocus}
                useSimpleKey={true}
                errors={RequestErrors.errors}
                onBlur={onBlur}
                onChange={changeInputValue}
                label="Muut kulut"
                name="muu_kulut"
                data={FormValues}
                edit={true}
              />
            </div>
            <div className="right">
              <InputText
                onFocus={onFocus}
                useSimpleKey={true}
                errors={RequestErrors.errors}
                onBlur={onBlur}
                onChange={changeInputValue}
                label="Muiden kulujen selite"
                name="muu_selite"
                data={FormValues}
                edit={true}
              />
            </div>
          </div>
          <div className="left">
            {RequestErrors.errors &&
              RequestErrors.errors.at_least_one_price && (
                <p>{RequestErrors.errors.at_least_one_price}</p>
              )}

              {FormValues.edit&&<div className="save-btn"  onClick={(e) => {
                cancel();
              }}><span>Peru</span></div>}
            <div
              className="save-btn"
              onClick={(e) => {
                tryToSubmit();
              }}
            >
              <span>Tallenna</span>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="form-blocks form-blocks-tapahtumat">
         
            <p>Käyttäjän palkka on jo laskettu</p>
          
        </div>
      );
    }
  };
 
  const getTable = () => {
    if (
      TapahtumaData.period &&
      TapahtumaData.person_id &&
      TapahtumaData.person_id != 0
    ) {
      if (TapahtumaData.data && TapahtumaData.data.tapahtumat) {
        if (TapahtumaData.data.tapahtumat.length == 0) {
          return (
            <tr className="no-results-row">
              <td colSpan={7}><p>Ei merkintöjä</p></td>
            </tr>
          );
        } else {
          return TapahtumaData.data.tapahtumat.map((item, index) => {
            return (
              <tr key={index} className="data-row">
               
                <td>
                  {item.tilausnumero}
                </td>
                <td className={makeGray(item.juoma_kulut)}>
                  {format(item.juoma_kulut)}
                </td>
                <td className={makeGray(item.kasittely_kulut)}>
                  {format(item.kasittely_kulut)}
                </td>
                <td className={makeGray(item.perinta_kulut)}>
                {format(item.perinta_kulut)}
                </td>
                <td className={makeGray(item.muu_kulut)}>
                 {format(item.muu_kulut)}
                </td>
                <td className={item.muu_selite?"":"gray"}>
              {item.muu_selite?item.muu_selite:"-"}
                </td>
                <td className="no-print">
                {TapahtumaData.data.can_add&&<div className="edit-btn" onClick={(e)=>{remove(item)}}><span>Poista</span></div>}
                  {TapahtumaData.data.can_add&&<div className="edit-btn" onClick={(e)=>{edit(item)}}><span>Muokkaa</span></div>}</td>
              </tr>
            );
          });
        }
      }
    } else {
      return (
        <tr className="no-results-row">
          <td colSpan={7}><p>Valitse henkilö ja kausi</p></td>
        </tr>
      );
    }
    /* return TapahtumaData.data.tapahtumat.map((item,index) =>{

        return (<tr key={index} className="data-row"><td><Link to={"/kayttajahallinta/"+item.id}>{item.name}</Link></td><td><Link to={"/kayttajahallinta/"+item.id}>{item.email}</Link></td><td><Link to={"/kayttajahallinta/"+item.id}>{getRole(item.adminrole_id)}</Link></td></tr>);
      
      });*/
  };

  return (
    <div className="mycontent">
      <div className="inner">
        <div className="topic-box">
          <div className="inner tapahtumat-selectors no-print">
            <h2>Tapahtumat</h2>
           
            {!FormValues.edit&&period_object}
            {!FormValues.edit&&getSelectPersonFilter()}

            {!FormValues.edit && TapahtumaData.data && TapahtumaData.data.tapahtumat &&TapahtumaData.data.tapahtumat.length>0&&<div className="edit-btn" onClick={(e)=>{

window.print();
	}}><span>Tulosta</span></div>}

          
          </div>
        </div>
        <div className="box box-tapahtumat">
          {getAddForm()}

          
          {!FormValues.edit&&<table className="table-new table-tapahtumat padd-top">
            <tbody>
              <tr className="text-header">
              
                <th>Tilausnumero</th>
                <th>Juomakulut</th>
                <th>Käsittelykulut</th>
                <th>Perintäkulut</th>
                <th>Muut kulut</th>
                <th>Muut kulut kuvaus</th>
                <th className="no-print"></th>
              </tr>
              {getTable()}
            </tbody>
          </table>
}
        </div>
      </div>
    </div>
  );
}
