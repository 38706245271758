import React,{useState,useEffect} from "react";

import { useStore } from "../store/useStore";
import axios from "axios";
import { config } from "../config";
import InputDouble from "./form/InputDouble.js";
import TopNav from "./TopNav.js";
import Period from "./form/Period.js";
import Notify from "./Notify.js";
import { apiClient } from "../apiCall";

import SearchField from "./SearchField.js";

import {format} from "../commonFunctions";

import {
	BrowserRouter as Router,
	StaticRouter, // for server rendering
	Route,
	Link
	// etc.
  } from "react-router-dom";
  export const defaultLaskentaData = {
	loading:true,
	data:[],
	period:null,
	showlisa:false,
	new_id:"",
	
};
export default function Palkanlaskenta() {
	const { state, dispatch } = useStore();
	const {common}=state;
	const apiCall = apiClient(state, dispatch);
	console.log("UI",state.ui);


	const [LaskentaData, setLaskentaData] = useState({
		...defaultLaskentaData
		
    });







	const apiResponse=(data)=>{

		

	
		
	
	
		if(data){
			
			
			
			setLaskentaData({...LaskentaData,loading:false,data:[...data]});
			
		}


	  };
	  const getPeriod=()=>{

		
		if(common.current_period==null){ return null; }


		

		if(state.ui.period==null){
			return common.current_period;


		}
		
		return state.ui.period;

	  }

const period=getPeriod();
	useEffect(() => {
			
	
		if(period==null){ return; }
		

	
		console.log("QUERY",period);
		var url="/listwagessimple/"+period;

		fetch( config.apiUrl+url,{headers: new Headers({
			'Authorization': 'Bearer '+state.user.token
			
		  })})
		  .then((res) => res.json())
		  .then(data =>apiResponse(data))

	 
		

	  }, [period]);
	
	if(!state.users.items){
		return null;
	}
	const getPalkkaLink=(item,wage)=>{
		console.log("MY WAGE",wage);

		let url="/palkanlaskenta/"+item.person.id+"/"+period;
		const linktocreatemain=<Link to={url}>Aloita peruspalkan laskenta</Link>
		
		var hasOnlyLisa=true;
		for(var i=0;i<item.wages.length;i++){
			console.log("TYPENI",item.wages[i].type);
			if(item.wages[i].type=="main"){
				hasOnlyLisa=false;
				break;
			}
		}
		
		if(item.wages.length==0){
			return <span>ok</span>
		}
		if(wage.type==="lisa"){
			url+="/"+wage.id;
		}
		if(wage.confirmed===1){


			return <span><Link className="arrow-right" to={url} >Valmis</Link>{hasOnlyLisa&&linktocreatemain}</span>
		}else{

			

			return <span><Link className="arrow-right" to={url} >Kesken </Link>{hasOnlyLisa&&linktocreatemain}</span>
			


		}
		/*if(item.wages.length==0){

			return <Link className="arrow-right" to={"/palkanlaskenta/"+item.person.id+"/"+period} >Aloita laskenta</Link>
		}
		

		if(item.wages.length==1){
			if(item.wages[0].confirmed===1){
				return <Link className="arrow-right" to={"/palkanlaskenta/"+item.person.id+"/"+period} >Valmis</Link>
			}else{

				
				return <Link className="arrow-right" to={"/palkanlaskenta/"+item.person.id+"/"+period} >Kesken </Link>
			}
			
		}

*/


		return  null;
	}
	const getVar=(item,key)=>{

		console.log("ITEM",item);
		return (item[key]!==0?format(item[key]):"-");
		if(item.confirmed===1){


			return (item[key]!==0?format(item[key]):"-");


		}else{

			return format(item.calculation[key].value);
		}

		
	}
	const getRow=(item,index)=>{



		return   <tr key={index}><td>{item.person.lastname} {item.person.firstname}</td>
		
		<td>{item.role ?item.role.name:"Ei määritetty"}</td>
		<td>{(item.wages.length!==0?getVar(item.wages[0],"brutto"):"-")}</td>
		<td>{(item.wages.length!==0?getVar(item.wages[0],"nettopalkka"):"-")}</td>
		<td>{(item.wages.length!==0?getVar(item.wages[0],"maksetaan"):"-")}</td>

		<td>{/*getPalkkaLink(item)*/}</td>
		</tr>;

	};
	const getCompany=(company,index)=>{


		console.log("ITEMI OLI",company);
		let data = company.map((item,index) =>
		getRow(item,index)
		);


	
		return (<tbody className="table-new">
			
			
			<tr class="company-header"><th colSpan="6">{company[0].person.department.name}</th></tr>
			<tr class="field-header"><th>Tyyppi</th><th>Rooli</th><th>Brutto</th><th>Netto</th><th>Maksetaan</th><th>Status</th></tr>
			
			 
			<tbody>
			{data}
				
					
			</tbody>		
			
			
		</tbody>);


	
	}
	const listItems = LaskentaData.data.map((item,index) =>
		getRow(item,index)
	);


	const changePeriod=(d,v)=>{

		
		if(getPeriod()!=v){
		dispatch({ type: "CHANGE_PERIOD", period: v });
		setLaskentaData({...LaskentaData,loading:true});

		}
		
		
	};	

	if(!period){

		return (<div>Ladataan</div>);
	}
	if(!state.companies.companies){

		return null;
	}

	
	console.log("DATANI",LaskentaData.data);
	/*let companies=[];
	for(var i=0;i<LaskentaData.data.length;i++){
		console.log(LaskentaData.data[i]);
		if(companies[LaskentaData.data[i].person.department_id]==null){
			companies[LaskentaData.data[i].person.department_id]=[];
		}
		companies[LaskentaData.data[i].person.department_id].push(LaskentaData.data[i]);
		
	}
	companies = companies.filter(function (el) {
		return el != null;
	  });
	  const listItems2 = companies.map((item,index) =>
		getCompany(item,index)
	);*/

	
	const headerKeys=[
		["lastname","Nimi"],
		["tyyppi","Tyyppi"],
		["maksupaiva","Maksupäivä"],
		
		["role","Rooli"],
		
		[null,"Brutto"],
		[null,"Netto"],
		[null,"Maksetaan"],
		[null,"Status"]
	];
	const setOrdering=(v)=>{


		
		if(state.common.sortIndexPalkanlaskenta==v){
			
			if(state.common.sortIndexModePalkanlaskenta=="asc"){

				dispatch({ type: "SORT_PALKANLASKENTA",sortIndexPalkanlaskenta:state.common.sortIndexPalkanlaskenta, sortIndexModePalkanlaskenta: "desc" });

			}else{
				dispatch({ type: "SORT_PALKANLASKENTA",sortIndexPalkanlaskenta:state.common.sortIndexPalkanlaskenta, sortIndexModePalkanlaskenta: "asc" });

			}
		}else{

			dispatch({ type: "SORT_PALKANLASKENTA", sortIndexPalkanlaskenta: v });

		}
		/*alert(e);

alert(state.common.sortIndexMode);*/

	};
	const getSorting=(a,b)=>{

		console.log("sorttti",state.common.sortIndexPalkanlaskenta,state.common.sortIndexMode,a,b);
		
		let av=0;
		let bv=0;
		if(state.common.sortIndexPalkanlaskenta=="role"){

console.log(a.role);
		if(a.role){

			av= a.role.role_id;
		}else{

			av="Ei määritetty";
		}
		if(b.role){

			bv= b.role.role_id;
		}else{

			bv="Ei määritetty";
		}


		}else{

			if(state.common.sortIndexPalkanlaskenta=="lastname"){
				av= a.person.lastname.toUpperCase();
				bv=b.person.lastname.toUpperCase();

			}
		
		}
		
		
		if(state.common.sortIndexPalkanlaskenta=="role"){

			let v= av> bv ? 1 : -1;
			return v*(state.common.sortIndexModePalkanlaskenta==="asc"?1:-1);
			
		}else{
			let v= av> bv ? 1 : -1;
			return v*(state.common.sortIndexModePalkanlaskenta==="asc"?1:-1);

		}
			
	

		
		//return a[state.common.sortIndexPersons].toUpperCase() > b[state.common.sortIndexPersons].toUpperCase() ? -1 : 1;
		

	};
	const formatDate=(date="")=>{
		let dates=date.split("-")
		if(dates.length===3){

			return dates[2]+"."+dates[1]+"."+dates[0];
		}
		return date;
	}
	const getPersonItemRow=(index,item)=>{

		console.log("ASKING PERSON ITEM ROW");
		if(item.wages.length==0){

			return getPersonItemZeroRow(index,item);
		}
		var lastname=item.person.lastname;
		var firstname=item.person.firstname;
		var role=item.role;

		console.log(item.wages,"WAGESII");
		return item.wages.map((wage,index) =>{

		return <tr className="data-row" key={index}><td>{lastname} {firstname}</td>
		<td className={(wage.type==="main"?"peruspalkka":"lisapalkka")}>{(wage.type==="main"?"Peruspalkka":"Lisäpalkka")}</td>
		<td>{formatDate(wage.maksupaiva)}</td>
		<td>{item.role ?item.role.name:<span className="error-wage">Ei määritetty</span>}</td>
		<td>{getVar(wage,"brutto")}</td>
		<td>{getVar(wage,"nettopalkka")}</td>
		<td>{getVar(wage,"maksetaan")}</td>

		<td>{(item.role?getPalkkaLink(item,wage):<Link   className="error-wage" to={"/persons/"+item.person.id+"/roles"}>Aseta rooli</Link>)}</td>
		</tr>;

		});

		return  <tr className="data-row" key={index}><td>{item.person.lastname} {item.person.firstname}</td>
		<td>{item.wages[0].type}</td>
		<td>{item.role ?item.role.name:<span className="error-wage">Ei määritetty</span>}</td>
		<td>{(item.wages.length!==0?getVar(item.wages[0],"brutto"):"-")}</td>
		<td>{(item.wages.length!==0?getVar(item.wages[0],"nettopalkka"):"-")}</td>
		<td>{(item.wages.length!==0?getVar(item.wages[0],"maksetaan"):"-")}</td>

		<td>{(item.role?getPalkkaLink(item):<Link   className="error-wage" to={"/persons/"+item.person.id+"/roles"}>Aseta rooli</Link>)}</td>
		</tr>;

	}
	const getPersonItemZeroRow=(index,item)=>{
		return  <tr className="data-row" key={index}><td>{item.person.lastname} {item.person.firstname}</td>
		<td></td>
		<td></td>
		<td>{item.role ?item.role.name:<span className="error-wage">Ei määritetty</span>}</td>
		<td>-</td>
		<td>-</td>
		<td>-</td>

		<td>{(item.role? <Link to={"/palkanlaskenta/"+item.person.id+"/"+period}>Aloita laskenta</Link> :<Link   className="error-wage" to={"/persons/"+item.person.id+"/roles"}>Aseta rooli</Link>)}</td>
		</tr>;

	}



	const getPalkat=(company_id)=>{



		var PALKAT=LaskentaData.data.sort((a, b) => getSorting(a,b)).filter(item => item.person.department_id===company_id);

		if(PALKAT.length==0){
			return  <tr className="no-results-row"><td>Ei tuloksia ajanjaksolle</td></tr>;
		}
		



		return LaskentaData.data.sort((a, b) => getSorting(a,b)).filter(item => item.person.department_id===company_id).map((item,index) =>



		

		{return getPersonItemRow(index,item);}
		






		);


		




	};
	const getHeader=()=>{

		return headerKeys.map((item,index) =>

		<th onClick={(e) => {
			setOrdering(item[0]);
		}} className={item[0]?"orderable "+(item[0]===state.common.sortIndexPalkanlaskenta?"order-active "+state.common.sortIndexModePalkanlaskenta:null):null} key={index}>{item[1]}</th>
		
		
		  );


	};

	const userHeader=<tr className="text-header">{getHeader()}</tr>;

	const departments=state.companies.companies.map((item) =>
		

		<tbody key={item.id} className={item.style}>
			<tr className="header"><th colSpan={headerKeys.length}>
				
				<h2><span className="small-info">Yritys</span>{item.name}</h2>
				
				
				</th></tr>
			{userHeader}

			{LaskentaData.loading?<tr className="tr-loading"><td colSpan={6}>Ladataan</td></tr>:getPalkat(item.id)}
			
		</tbody>


	  );

console.log("state.common.min_wage_period",common)
const showLisapalkka=()=>{
setLaskentaData({...LaskentaData,showlisa:!LaskentaData.showlisa,new_id:""})

};
const trytoaddnew=()=>{


	if(LaskentaData.new_id!=""){
		let url="lisapalkat/add";
		let data={period:period,persons_id:LaskentaData.new_id};
		
		apiCall.ADD(url, data,function(status,err_msgs,response){
		
			if(status==400){

				console.log(err_msgs);
				
				//setErrors({errors:{...err_msgs}})
				//setRequestErrors({errors:{...err_msgs}});
	
				//setFolderData({...FolderData,errors:err_msgs})
			}else if(status==200){
				
				var url=response.data.persons_id+"/"+response.data.period+"/"+response.data.id;
				alert("OK"+url);
				//setUserData({...UserData,"edit":false});
				//apiCall.GET("users");
				//setIsStored(true);
			}
	
		}).then(() => {
			//apiCall.GET('destinations');
			
		});
		
	}

};
const person_selector=()=>{

	const person_options=LaskentaData.data.map((item,index) =>
		 <option value={item.person.id}>{item.person.lastname} {item.person.firstname}</option>
	);

	return <select onChange={(e)=>{
		var nw=e.currentTarget.value!="0"?parseInt(e.currentTarget.value):null;
		setLaskentaData({...LaskentaData,new_id:nw});
		
		}} value={LaskentaData.new_id}><option value="0">--Valitse henkilö--</option>{person_options}</select>

}
	return (
		<div className="mycontent">
			
			<div className="inner">
			<div className='period-topic topic-box'>
					<div className="inner">
						<h2>Palkanlaskenta</h2><Period min={state.common.min_wage_period} max={state.common.max_wage_period} onChange={changePeriod} name="period"  value={period} edit={true}/>
						
						{/*<button onClick={showLisapalkka}>Lisää uusi lisäpalkka</button>*/}
						
						<SearchField/>
						</div>
					
					
					</div>
				
				<div className="box">
				{LaskentaData.showlisa&&<div className='lisaform'>{person_selector()}<button onClick={trytoaddnew}>Lisää</button></div>}
					<table className="table-new">
						{departments}
			
					</table>
					
		</div>
		</div></div>		
	);
}
