import React,{useState,useEffect} from "react";
import { useStore } from "../store/useStore";
import { searchSetting } from "../commonFunctions";
import axios from "axios";
import { config } from "../config";
import InputText from "./form/InputText.js";
import InputBoolean from "./form/InputBoolean.js";

import InputSelect from "./form/InputSelect.js";
import InputDouble from "./form/InputDouble.js";
import { useParams } from 'react-router-dom';
import { apiClient } from "../apiCall";

import AdminUser from "./form/AdminUser.js";



import SettingPair from "./form/SettingPair.js";

import { getSettingValue,getSettingType,getSettingIndex,getCommonSetting } from "../commonFunctions";

import TaxRates from "./form/TaxRates.js";


import TopNav from "./TopNav.js";

import {
	BrowserRouter as Router,
	StaticRouter, // for server rendering
	Route,
	Link
	// etc.
  } from "react-router-dom";



  export const defaultKayttajaData={

	loaded:false,
    data:null,
    showaddform:false,
    sending:false,
	success_text:null
	
};
export const defaultPwdData={

	password1:"",
    password2:"",
    change:false
	
	
};
export const DefaultRequestErrors={

	errors:null
}

export default function KayttajaEditor({ id: propId }) {
    const { id: paramId } = useParams();
    const id = propId || paramId;
    const { state, dispatch } = useStore();
   
    const [PwdData, setPwdData] = useState({
		...defaultPwdData
		
    });

    const [KayttajaData, setKayttajaData] = useState({
		...defaultKayttajaData
		
    });

    const [RequestErrors,setRequestErrors] = useState({
        ...DefaultRequestErrors
          
        });
        const apiCall = apiClient(state, dispatch);


    const getRole=(admin_role_id=null)=>{

        if(admin_role_id){
            console.log("commoo",state.common.adminroles);

            const found = state.common.adminroles.find((element) => element.id == admin_role_id);

            if(found){
              return found.name;
            }
        }
        return "-";

    };
    const vaihdaPassu=(name,value)=>{
        setPwdData({...PwdData,[name]:value});
    };
    const updatePwdField=(name,value,index)=>{

        setPwdData({...PwdData,[name]:value});

    }
    const onFocusPwd=(name,value,index)=>{
        let key=name;
      
        let errors={...RequestErrors.errors};
        errors[key]=null;
        setRequestErrors({errors:{...errors}});
    }
    const onFocus=(name,value,index)=>{
        let key=name;
      
        let errors={...RequestErrors.errors};
        errors[key]=null;
        setRequestErrors({errors:{...errors}});
      };

      const updateField=(name,value)=>{

        setKayttajaData({...KayttajaData,data:{...KayttajaData.data,[name]:value}});
    };

    const changeRole=(name,value)=>{
        setKayttajaData({...KayttajaData,data:{...KayttajaData.data,[name]:parseInt(value)}});

    };
    const apiResponse=(data)=>{

       
        setKayttajaData({...defaultKayttajaData,data:data,loaded:true,showaddform:false});

    }
    const updateFromserver=()=>{

      var url="/kayttajahallinta/"+id;

      fetch( config.apiUrl+url,{headers: new Headers({
        'Authorization': 'Bearer '+state.user.token
        
        })})
        .then((res) => res.json())
        .then(data =>apiResponse(data))

        
    }
	useEffect(() => {
			
	
		
		
    updateFromserver();
	
		
		
	 
		

	  }, [id]);
    const addNewShowHide=()=>{

      setKayttajaData({...KayttajaData,showaddform:!KayttajaData.showaddform});
    };

      if(KayttajaData.loaded==false || state.common.adminroles==null){
        return null;
      }
     const succesfullyAdded=()=>{

      
        setKayttajaData({...KayttajaData,showaddform:false});
       updateFromserver();
     };
     
	
     if(!state.common || !state.common.me){
        return null;

    }
    const save=(e)=>{

        
        if(checkIsOk()){

          
            var data={

                name:KayttajaData.data.name,
                email:KayttajaData.data.email,
            }
            if(IS_ME){
                if(PwdData.change){
                    data.password=PwdData.password1;
                }
            }else{


                data.adminrole_id=KayttajaData.data.adminrole_id;

                
            }

            setKayttajaData({...KayttajaData,sending:true,success_text:null});


            let url="kayttajahallinta/"+id;
            apiCall.ADD(url, {...data},function(status,err_msgs,response){
                if(status==400){
                    setKayttajaData({...KayttajaData,sending:false,success_text:null});
                  setRequestErrors({errors:{...err_msgs}});
                  //setFolderData({...FolderData,errors:err_msgs})
                }else if(status==200){
                    setKayttajaData({...KayttajaData,sending:false,success_text:"Käyttäjän tiedot päivitetty"});
            
                }else{

                    setKayttajaData({...KayttajaData,sending:false,success_text:null});
                }
        });
    }

    };
	const ME=(state.common.me);
    const types=state.common.adminroles;

    const IS_ME=ME.id===KayttajaData.data.id?true:false;
    const checkIsOk=()=>{


        if(KayttajaData.data.name.trim()!="" && KayttajaData.data.email.trim()!=""){
            
          

        }else{
            return false;
        }
        console.log("IS_ME",IS_ME);
        
        if(IS_ME){
            if(PwdData.change){
                if(PwdData.password1.trim().length<7){
                    return false;
                }
                if(PwdData.password1.trim()!==PwdData.password2.trim()){
                    return false;
                }

            }

        }else{
            if(KayttajaData.data.adminrole_id==0){
                return false;
            }

        }
        // && parseInt(KayttajatData.adminrole_id)>0
        return true;

    };
const isOk=checkIsOk();




    const password_fiels=<div className="change-pwd">
      <InputBoolean  label={"Vaihda salasana"} onChange={vaihdaPassu} data={PwdData} name="change" edit={true}/>
   {PwdData.change?<div className="form-blocks">
   <div className="form-block">
    <div className="left">
<InputText  onFocus={onFocusPwd} useSimpleKey={true} onChange={updatePwdField} name="password1" label={"Salasana"} data={PwdData} errors={RequestErrors.errors} edit={true}/>
</div>
<div className="right">
<InputText  onFocus={onFocusPwd} useSimpleKey={true} onChange={updatePwdField} name="password2" label={"Vahvista salasana"} data={PwdData} errors={RequestErrors.errors} edit={true}/>
</div></div></div>:null}
    </div>

    return <div className="mycontent">
        <div className="box">
            <h2>{KayttajaData.data.name}</h2>
        <div className="form-blocks">
            <div className="form-block">
                <div className="left">
                    <InputText  onFocus={onFocus} useSimpleKey={true} onChange={updateField} name="name" label={"Nimi"} data={KayttajaData.data} errors={RequestErrors.errors} edit={true}/>
                </div>
                <div className="right">
                    <InputText  onFocus={onFocus} useSimpleKey={true} onChange={updateField} name="email" label={"Email"} data={KayttajaData.data} errors={RequestErrors.errors} edit={true}/>
                </div>
                <div className="left">
                {!IS_ME&&<InputSelect  onFocus={onFocus}  label={"Käyttäjän rooli"} useSimpleKey={true} onChange={changeRole} zero={"--Valitse rooli--"} options={types} errors={RequestErrors.errors} data={KayttajaData.data} name="adminrole_id" edit={true}/>}
                </div>
               
                {IS_ME?password_fiels:null}
            </div>
            {KayttajaData.success_text&&<p className='success'>{KayttajaData.success_text}</p>}
            {KayttajaData.sending==false&&
            <div className="right"><div onClick={save} className={"save-btn"+(!isOk?" disabled":"")}><span>Tallenna</span></div></div>
            }
        </div>
       
        
        
     
        </div>
        </div>
        
       
    
}
